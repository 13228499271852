export default {
    baseURL: 'https://test.leslunettesoho.com',
    login: '/api/v1/auth/login',
    allAgency: '/api/v1/agency/get-agency',
    swich: '/api/v1/auth/switch-agency',
    updatePass: '/api/v1/auth/update-password',
    createSupplier: '/api/v1/supplier/configuration/create-supplier',
    allSupplier: '/api/v1/supplier/get-supplier',
    createAssurance: '/api/v1/insurance/configuration/create-insurance',
    allAssurance: '/api/v1/insurance/get-insurance',
    createMarque: '/api/v1/brand/configuration/create-brand',
    allMarque: '/api/v1/brand/get-brands',
    createGamme: '/api/v1/range/configuration/create-range',
    allGamme: '/api/v1/range/get-ranges',
    updateGamme: '/api/v1/range/configuration/update-range',
    createPrice: '/api/v1/price/configuration/create-cat',
    allPrice: '/api/v1/price/get-cats',
    deletePrice: '/api/v1/price/configuration/delete-cat',
    updatePrice: '/api/v1/price/configuration/update-cat',
    createProduct: '/api/v1/product/configuration/create-product',
    saveProductPrice: '/api/v1/price/configuration/create-multiples-product-price',
    getProduct: '/api/v1/product/get-products',
    addProductStock: '/api/v1/stock/add-product-to-stock',
    limitStock: '/api/v1/stock/add-stock-limitation',
    getCommerciaux: '/api/v1/staff/get-staffs',
    createCommercial: '/api/v1/staff/configuration/create-staff',
    deleteCommercial: '/api/v1/staff/configuration/delete-staff',
    updateCommercial: '/api/v1/staff/configuration/update-staff',
    addAgent: '/api/v1/auth/add-user',
    getProductParginate: '/api/v1/product/get-paginated-product',
    wallet: '/api/v1/wallet/get-wallets',
    agenceStats: '/api/v1/wallet/get-agency-wallet-stats',
    agenceHistorique: '/api/v1/wallet/get-wallets-transactions',
    allUser: '/api/v1/admin/get-all-users',
    createWallet: '/api/v1/wallet/create-wallet',
    addResponsible: '/api/v1/agency/configuration/add-responsible'
}
